.table-downloadable-products,
.table-additional-addresses-items,
.table-order-items {
    text-align: center;
}

.ticket-listing {
    --nb-colmuns: 2;
}

.subscription-product-blocks {
    .subscription-product-infos {
        margin-top: 0;
    }
}
