.mgt-xxs { display: none; }
.mgt-xs  { display: block; }
.mgt-sm  { display: none; }
.mgt-md  { display: none; }
.mgt-lg  { display: none; }
.mgt-hd  { display: none; }


#mgt-developer-toolbar {
    background: #dd7870;
    color:black;
}

