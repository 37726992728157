body.aw-et-product-page,
body.contentmanager-contenttype-actu,
body.contentmanager-contenttype-projet,
body.contentmanager-contenttype-edition {
    font-size: 14px;

    .page-main {
        max-width: $container-xs;
    }
    .edition-related {
        &-list {
            &-item {
                &-body {
                    &-img {
                        &-container {
                            display:block;
                            position: relative;
                            top: 0;
                            left: 0;
                            width: 255px;
                            height: 191.25px;
                        }

                        &.placeholder {
                            object-fit: contain;
                            object-position: top center;
                        }
                    }

                    &-content {
                        width: calc(100% - 255px);
                    }
                }
            }
        }
    }
}
