// Breakpoints
$screen-hd: 1680px;
$screen-lg: 1440px;
$screen-md: 1024px;
$screen-sm: 769px;
$screen-xs: 425px;

// Container width
$container-hd: 1200px;
$container-lg: 1200px;
$container-md: 980px;
$container-sm: 100%;
$container-xs: 100%;
$container-xxs: 100%;

// Font size
$font-size-hd: 16px; //17px cause alignement problems on header menu
$font-size-lg: 16px;
$font-size-md: 15px;
$font-size-sm: 14px;
$font-size-xs: 13px;

$h1-f-size: 2.5rem;
$h2-f-size: 1.75rem;
$h3-f-size: 1.5rem;
$h4-f-size: 1.3rem;
$h5-f-size: 1.1rem;
$h6-f-size: 1rem;

$p-f-size: 1rem;

// Colors
$white: #fff;
$lightest-grey: #f5f5f5;
$lightest-grey-nearly: #eaeaea;
$lighter-grey: #c4c4c4;
$light-grey: #a7a7a8;
$grey: #8f8f8f;
$dark-grey: #3d3d3d;
$black: #272727;
$ultra-black: #000;

$light-green: #85bf84;
$green: #95be1b;
$dark-green: #00746f;

$lavender: #af78Ab;
$orange: #ec8255;
$blue: #47a9c5;
$blue-two: #6295F9;
$blue-intense: #4267b2;
$red: #e6545b;
$gold: #f9c862;
$gold-light: #fedb93;
$faded-lavender: #9a9aca;

$primary: $dark-green;
$primary-light: #b3d5d4;
$primary-lighter: #b3d5d4a5;
$alternative-green: $green;

// Prefixers
$prefix-for-webkit: true !default;
$prefix-for-mozilla: true !default;
$prefix-for-microsoft: true !default;
$prefix-for-opera: true !default;
$prefix-for-spec: true !default;

// Gradient

// Fonts
$roboto: 'Roboto', sans-serif;
$noto-bold: 'NotoSansBold', sans-serif;
$icomoon: 'Icomoon';


// SVG
$magnifier-svg-grey: url("data:image/svg+xml;utf8,<svg width=\'16\' height=\'16\' viewBox=\'0 0 40 40\'  fill=\'%23a7a7a8\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M15.553 31.107c8.59 0 15.554-6.964 15.554-15.554S24.143 0 15.553 0 0 6.964 0 15.553c0 8.59 6.964 15.554 15.553 15.554zm0-3.888c6.443 0 11.666-5.225 11.666-11.668 0-6.442-5.225-11.665-11.668-11.665-6.442 0-11.665 5.223-11.665 11.665 0 6.443 5.223 11.666 11.665 11.666zm12.21 3.84a2.005 2.005 0 0 1 .002-2.833l.463-.463a2.008 2.008 0 0 1 2.833-.003l8.17 8.168c.78.78.78 2.05-.004 2.833l-.462.463a2.008 2.008 0 0 1-2.834.004l-8.168-8.17z\' fill-rule=\'evenodd\'/></svg>");
$magnifier-svg-black: url("data:image/svg+xml;utf8,<svg width=\'16\' height=\'16\' viewBox=\'0 0 40 40\'  fill=\'%23272727\' xmlns=\'http://www.w3.org/2000/svg\'><path d=\'M15.553 31.107c8.59 0 15.554-6.964 15.554-15.554S24.143 0 15.553 0 0 6.964 0 15.553c0 8.59 6.964 15.554 15.553 15.554zm0-3.888c6.443 0 11.666-5.225 11.666-11.668 0-6.442-5.225-11.665-11.668-11.665-6.442 0-11.665 5.223-11.665 11.665 0 6.443 5.223 11.666 11.665 11.666zm12.21 3.84a2.005 2.005 0 0 1 .002-2.833l.463-.463a2.008 2.008 0 0 1 2.833-.003l8.17 8.168c.78.78.78 2.05-.004 2.833l-.462.463a2.008 2.008 0 0 1-2.834.004l-8.168-8.17z\' fill-rule=\'evenodd\'/></svg>");

/***** LAYOUT *****/

// Horizontal padding
$padding-horizontal-sm: 8vw;
$padding-horizontal-xs: 6.5vw;
$padding-horizontal-xxs: 5vw;

$top-sticky-offset: 150px;

$welcome-banner-height: 32px;
