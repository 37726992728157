.footer {
    .ct-view-footer {
        .block-content-footer {
            .columns-footer {
                .social-media {
                    .ct-field-repeater_fields {
                        .ct-view-footer_link {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}
