body.search-content-index,
body.algolia-list {
    .algolia-right-container {
        .ais-Hits-item {
            width: calc((100vw - 30px * 2) / 2 - 15px);
            text-align: left;

            .result-wrapper {
                .result {
                    height: calc(100vw * 0.8);
                }
            }
        }
    }
}

body.algolia-list-actu {
    .algolia-right-container {
        .ais-Hits-item {
            .result-wrapper {
                &.not-first {
                    background: none !important;

                    a,
                    a:active,
                    a:visited {
                        color: $black !important;
                    }

                    a:hover {
                        color: $primary !important;
                    }

                    .result {
                        color: $black;

                        &-content {
                            .card-link {
                                background-image: linear-gradient($primary, $primary);
                            }
                        }
                    }

                    .result-sub-content {
                        &-data {
                            &-header {
                                .result-date {
                                    color: $primary;
                                }
                            }
                        }
                    }
                }
                &.first-entry {
                    .result-sub-content {
                        &-data {
                            &-header {
                                .result-date {
                                    color: $white;
                                }
                            }
                        }
                    }
                }

                .result {
                    display: flex;
                    flex-direction: row;

                    &-content {
                        .card-link {
                            background-image: linear-gradient($white, $white);
                            color: $black;
                        }
                    }

                    .result-sub-content-img-container {
                        width: 289px;
                        max-width: 60%;
                    }

                    .result-sub-content {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        padding: 10px;
                        width: 100%;
                        height: 100%;

                        .result-sub-content-data {
                            .result-sub-content-data-tags {
                                color: $primary;
                            }
                        }
                    }
                    .result-content {
                        width: calc(100% - 289px);
                    }
                }
            }
        }
    }
}
