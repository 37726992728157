$padding-horizontal: $padding-horizontal-xs;
$card-width: 294px;
$card-height: 220px;

body.contentmanager-contenttype-homepage {
    .page-main {
        .homepage {
            section {
                .especes-grid {
                    padding: 0 $padding-horizontal;
                }

                + .homepage_thematiques {
                    .ct-field-repeater_fields {
                        .rubrique {
                            padding-left: 0.75rem;
                            &::before {
                                left: -0.75rem;
                                width: 0.75rem;
                            }
                            &::after {
                                right: -0.75rem;
                                width: 0.75rem;
                            }
                        }
                    }
                }
                + .homepage_ctifl {
                    .homepage_ctifl_bottom {
                        &_figures {
                            font-size: 2.5rem;
                        }
                    }
                }
            }
            .homepage_social_body {
                .homepage_social_first_col {
                    .homepage-youtube {
                        iframe {
                            height: 300px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 500px) and (max-width: 768px) {
    body.contentmanager-contenttype-homepage {
        .page-main {
            max-width: $container-xs;

            .homepage {
                section {
                    margin: 50px 0;
                    &.homepage_recherche {
                    }
                    + .homepage_actualites {
                        .homepage_actualites-cards {
                            .actu-card-text {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}
